
const start = () => {
    const imageList = [
        '/wp-content/themes/chuuouhikari/assets/images/home/feature-2.jpg',
        '/wp-content/themes/chuuouhikari/assets/images/home/feature-3.jpg',
        '/wp-content/themes/chuuouhikari/assets/images/home/feature-4.jpg',
        '/wp-content/themes/chuuouhikari/assets/images/home/feature-5.jpg'
    ];

    const mvImageArea = document.getElementById("js-top-mv");

    imageList.map((path) => {
        const img = document.createElement("img");
        img.setAttribute("alt", "");
        img.setAttribute("src", path);
        img.setAttribute("class", "l-header__bg-img");
        mvImageArea.appendChild(img);
        gsap.set(img, { opacity: 0 });
    });

    const mvImages = document.getElementById("js-top-mv").querySelectorAll("img");
    let counter = 0;


    setTimeout(() => {
        nextImage();
    }, 3000);

    function nextImage() {
        if(counter == mvImages.length - 1) {
            gsap.to(mvImages[counter], { opacity: 0, duration: 1});
            gsap.to(mvImages[0], { opacity: 1, duration: 1});
            counter = 0;
        } else {
            gsap.to(mvImages[counter], { opacity: 0, duration: 1});
            gsap.to(mvImages[counter + 1], { opacity: 1, duration: 1});
            counter += 1;
        };
        console.log(counter);

        setTimeout(() => {
            nextImage();
        }, 3000);
    };
};

window.onload = function ()     {
    start();
}
